import { z } from 'zod';
import { nafSchema } from '@/schema/naf';

export const categoryJuridiqueSchema = z.object({
    code: z.number(),
    label: z.string(),
});
export const categoriesJuridiqueSchema = z.array(categoryJuridiqueSchema);

export const titreSchema = z.object({
    id: z.number(),
    natureTransaction: z.number(),
    siren: z.string(),
    denomination: z.string().nullable(),
    dateDepot: z.coerce.date().nullable(),
    nafId: z.number().nullable(),
    naf: nafSchema.optional(),
    dateCreationSociete: z.coerce.date().nullable(),
    categorieJuridique: z.number(),
    catJur: categoryJuridiqueSchema.optional(),
    activite: z.string().nullable(),
    codePostal: z.string().nullable(),
    commune: z.string().nullable(),
    adresse: z.string().nullable(),
    longitude: z.number().nullable(),
    latitude: z.number().nullable(),
    dureeExercice: z.number().nullable(),
    chiffreAffaires: z.number().nullable(),
    resultatNet: z.number().nullable(),
    capitauxPropres: z.number().nullable(),
    endettementNet: z.number().nullable(),
    ebe: z.number().nullable(),
    resultatExploitation: z.number().nullable(),
    dotationExploitation: z.number().nullable(),
    repriseExploitation: z.number().nullable(),
    chiffreAffairesNm1: z.number().nullable(),
    resultatNetNm1: z.number().nullable(),
    ebeNm1: z.number().nullable(),
    resultatExploitationNm1: z.number().nullable(),
    dotationExploitationNm1: z.number().nullable(),
    repriseExploitationNm1: z.number().nullable(),
    fileActe: z.string().nullable().nullable(),
    fileCompte: z.string().nullable().nullable(),
    ebeApresIs: z.number().nullable(),
    resultatExploitationApresIs: z.number().nullable(),
    multipleChiffreAffaires: z.number().nullable(),
    multipleResultatNet: z.number().nullable(),
    multipleEbe: z.number().nullable(),
    multipleResultatExploitation: z.number().nullable(),
    multipleEbeApresIs: z.number().nullable(),
    multipleResultatExploitationApresIs: z.number().nullable(),
    ebitdaEbeAvecAutresProduitsEtChargesExploitation: z.number().nullable(),
    nombreTotalPartsVendues: z.number().nullable(),
    montantTotalVente: z.number().nullable(),
    prixVenteUnePartSociale: z.number().nullable(),
    nombrePartsExistantesTotalSocieteVendue: z.number().nullable(),
    valeurNominaleTitreSocieteVendu: z.number().nullable(),
    capitalSocialSocieteVendu: z.number().nullable(),
    valeurEntreprise: z.number().nullable(),
    valeurCapitauxPropres: z.number().nullable(),
    ebitdaEbeAvecAutresProduitsEtChargesExploitationApresIs: z.number().nullable(),
    multipleEbitda: z.number().nullable(),
    multipleEbitdaApresIs: z.number().nullable(),
    valeurAjoutee: z.number().nullable(),
    chargePersonnel: z.number().nullable(),
    autresAchatsChargesExternes: z.number().nullable(),
    impotTaxe: z.number().nullable(),
    autresChargesExploitation: z.number().nullable(),
    chargesFinancieres: z.number().nullable(),
    chargesExceptionnelles: z.number().nullable(),
    participation: z.number().nullable(),
    impotSociete: z.number().nullable(),
    autresProduitsExploitation: z.number().nullable(),
    produitsFinanciers: z.number().nullable(),
    produitsExceptionnels: z.number().nullable(),
    stockNet: z.number().nullable(),
    clientNet: z.number().nullable(),
    autresCreancesNet: z.number().nullable(),
    disponibilite: z.number().nullable(),
    detteFournisseur: z.number().nullable(),
    autresDettes: z.number().nullable(),
    empruntsDettesFinancieres: z.number().nullable(),
    immobilisationIncorporelleNette: z.number().nullable(),
    immobilisationCorporelleNette: z.number().nullable(),
    immobilisationFinanciereNette: z.number().nullable(),
    acteCategorieVente: z.string().nullable(),
    etablissementOrigineFonds: z.string().nullable(),
    dateClotureExercice: z.coerce.date().nullable(),
    tauxIs: z.number().nullable(),
    multipleFondsChiffreAffaires: z.number().nullable(),
    tauxPartsVendues: z.number().nullable(),
});
