import { z } from 'zod';

export const rapportSchema = z.object({
    id: z.number(),
    titre: z.string(),
    type: z.number(),
    userId: z.number(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().nullable(),
});

const yearsSchema = z.object({
    year1: z.coerce.number(),
    year2: z.coerce.number(),
    year3: z.coerce.number(),
});

const minMaxSchema = z.object({
    min: z.coerce.number().nullable(),
    max: z.coerce.number().nullable(),
});

export const rapportSectionSchema = z.object({
    id: z.number(),
    rapportId: z.number(),
    type: z.number(),
    position: z.number(),
    data: z
        .object({ text: z.string() })
        // Comparables
        .or(z.object({ useSummary: z.boolean(), columns: z.array(z.string()).optional() }))
        // Target
        .or(
            z.object({
                name: z.string(),
                activity: z.string(),
                years: yearsSchema,
                ca: yearsSchema,
                rn: yearsSchema,
                ebe: yearsSchema,
                ebeLabel: z.string().nullable().optional(),
                rnLabel: z.string().nullable().optional(),
                customEBE: z.string().nullable(),
            })
        )
        // Market
        .or(
            z.object({
                naf: z.array(z.number()).optional(),
                secteur: z.array(z.number()).optional(),
                filter: z.object({
                    ca: minMaxSchema,
                    ratio: minMaxSchema,
                }),
                ca: z.coerce.number().nullable(),
                ratioType: z.coerce.number().nullable(),
                ratio: z.coerce.number().nullable(),
                year: z.coerce.number().nullable().optional(),
                search: z.coerce.string().nullable().optional(),
                location: z.coerce.number().nullable().optional(),
            })
        )
        // MarketEvolution
        .or(
            z.object({
                naf: z.coerce.number().optional(),
                secteur: z.coerce.number().optional(),
                ratio: z.coerce.number(),
            })
        )
        .or(z.object({})),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().nullable(),
});

export type RapportSectionType = z.infer<typeof rapportSectionSchema>;

export const rapportTitreSchema = z.object({
    rapportId: z.number(),
    titreId: z.number(),
    fields: z.array(z.string()),
});
export const rapportTransactionSchema = z.object({
    rapportId: z.number(),
    immoId: z.number(),
    view: z.string(),
    viewAlt: z.string().nullable(),
});

export const rapportSectionTypeSchema = z.object({
    id: z.number(),
    label: z.string(),
    position: z.number(),
    max: z.number(),
    rapportType: z.array(z.number()),
});

export const rapportSectionTypesSchema = z.array(rapportSectionTypeSchema);
