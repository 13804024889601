<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { z } from 'zod';
import { rapportSchema } from '@/schema/rapport';
import { useToast } from 'vue-toastification';
import { fetchApi, localeDate } from '@/helpers';
import { defaultCauditFieldsCfg } from '@/views/caudit/fields';

const toast = useToast();

const props = defineProps<{
    app: 'caudimmo' | 'caudit';
    id: number[];
}>();

const emits = defineEmits<{ close: [] }>();

const rapportListSchema = z.array(rapportSchema);
const rapports = ref<z.infer<typeof rapportListSchema>>([]);
const selected = ref<z.infer<typeof rapportSchema> | null>(null);
const loading = ref(false);

async function fetchRapports() {
    loading.value = true;
    rapports.value = await fetchApi({ url: `rapport/list/${props.app === 'caudit' ? 1 : 2}`, method: 'GET', schema: rapportListSchema }).catch(() => {
        toast.error("Une erreur s'est produite");
        return [];
    });

    loading.value = false;
}

async function add() {
    if (selected.value) {
        loading.value = true;
        await fetchApi({
            url: `rapport/${props.app}/addAsset`,
            method: 'POST',
            body: {
                rapportId: selected.value.id ?? 0,
                assetId: props.id,
                ...(props.app === 'caudimmo'
                    ? {
                          view: localStorage.getItem('view') ?? '',
                          viewAlt: localStorage.getItem('view_alt'),
                      }
                    : { fields: defaultCauditFieldsCfg }),
            },
        })
            .then(() => toast.success('Ajouté(s) avec succès au rapport'))
            .catch(() => toast.error("Une erreur s'est produite"));
        loading.value = false;
        emits('close');
    }
}

async function createRapport() {
    const titre = prompt('Comment voulez-vous le nommer ?');
    if (titre) {
        loading.value = true;
        await fetchApi({ url: 'rapport/create', method: 'POST', body: { type: props.app === 'caudit' ? 1 : 2, titre } })
            .then(async () => {
                toast.success('Nouveau rapport créé avec succès');
                await fetchRapports();
            })
            .catch(() => {
                toast.error("Une erreur s'est produite");
            });
    }
    loading.value = false;
}

onMounted(async () => {
    await fetchRapports();
});
</script>

<template>
    <Modal
        :custom="{ label: 'Créer un rapport', color: 'primary' }"
        :loading="loading"
        :show-accept="true"
        :show-cancel="true"
        :title="'Selectionnez un rapport'"
        @accept="add"
        @cancel="$emit('close')"
        @custom="createRapport"
        @close="$emit('close')">
        <DataTable
            v-if="rapports.length"
            v-model:selection="selected"
            selection-mode="single"
            paginator
            :rows="5"
            paginator-template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            current-page-report-template="{first} à {last} sur {totalRecords}"
            :value="rapports"
            class="w-full"
            size="small">
            <Column selection-mode="single" header-style="width: 1rem" header-class="!bg-gray-100"></Column>
            <Column :sortable="true" header="Nom" field="titre" class="font-bold" header-class="!bg-gray-100" />
            <Column :sortable="true" header="Créé le" field="createdAt" header-class="!bg-gray-100" class="w-[180px]">
                <template #body="slotProps">
                    {{ localeDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column :sortable="true" header="Modifié le" field="updatedAt" header-class="!bg-gray-100" class="w-[180px]">
                <template #body="slotProps">
                    {{ localeDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </DataTable>
        <p v-else>Aucun rapport n'est disponible</p>
    </Modal>
</template>
