<script setup lang="ts">
import { computed, ref } from 'vue';
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { useStore } from 'vuex';
import { ACTION_MENU_COLLAPSE, GETTER_MENU_STATE, MENU_STATUS } from '@/store/modules/navigation';
import { useRoute } from 'vue-router';
import { GETTER_USER } from '@/store/modules/auth';
import { RoleEnum } from '@/schema/profile';

const store = useStore();
const toggle = computed(() => store.getters[GETTER_MENU_STATE] === MENU_STATUS.EXPENDED);

const connectedProfile = computed(() => store.getters[GETTER_USER]);

const route = useRoute();

const app = computed(() => {
    if (route.path.includes('caudimmo')) {
        return 'caudimmo';
    } else if (route.path.includes('caudit')) {
        return 'caudit';
    } else {
        return null;
    }
});
const id = computed(() => Number(route.params.id ?? 0));
const showRapportMenu = ref(false);
</script>

<template>
    <div v-if="toggle" class="fixed left-0 top-0 z-10 size-full bg-gray-900/50" @click="store.dispatch(ACTION_MENU_COLLAPSE)"></div>
    <div class="fixed top-0 z-20 h-full w-[450px] bg-gray-100 px-8 pt-[40px] text-alt shadow-lg transition-all print:hidden" :class="toggle ? 'right-0' : 'right-[-450px]'">
        <fa class="absolute right-3 top-3 cursor-pointer text-2xl" :icon="['fas', 'xmark']" @click="store.dispatch(ACTION_MENU_COLLAPSE)" />
        <Profile></Profile>
        <div v-if="app === 'caudit'" class="flex flex-col">
            <hr class="my-3" />
            <div class="mt-2 text-xl"><fa :icon="['far', 'clipboard']" /> Rapports</div>
            <router-link class="flex items-center px-3 py-2" :to="`/${app}/rapports`"><fa :icon="['fas', 'eye']" class="mr-1" /> Consulter les rapports </router-link>
            <button v-if="id > 0 && !route.path.includes('rapport')" class="flex w-full items-center rounded-md px-3 py-2" @click="showRapportMenu = true">
                <fa :icon="['fas', 'file-circle-plus']" class="mr-1" /> Ajouter à un rapport
            </button>
        </div>
        <RapportMenu v-if="id > 0 && app && showRapportMenu" :id="[id]" :app="app" @close="showRapportMenu = false"></RapportMenu>
        <hr class="my-3" />
        <div class="flex flex-col">
            <router-link :to="'/'" class="mt-2 text-xl"><fa :icon="['fas', 'house']" /><span class="pl-1">Accueil</span></router-link>
            <div class="mt-2 text-xl"><fa :icon="['fas', 'square']" class="mr-1 mt-[3px]" /> Module 1 - Titres et fonds de commerce</div>
            <router-link :to="{ name: 'caudit' }" class="flex rounded-md px-3 py-1 text-sm font-medium hover:underline">• Multiples des sociétés</router-link>
            <router-link :to="{ name: 'pricePerPlace' }" class="flex rounded-md px-3 py-1 text-sm font-medium hover:underline">• Prix de vente par emplacement</router-link>
            <div class="mt-2 text-xl"><fa :icon="['fas', 'square']" class="mr-1 mt-[3px]" /> Module 2 - Immobilier</div>
            <router-link :to="{ name: 'caudimmo' }" class="flex items-center rounded-md px-3 py-2 text-sm font-medium hover:underline">• Prix de vente des locaux d'activité </router-link>
        </div>
        <hr class="my-3" />
        <div class="flex flex-col">
            <div class="mt-2 text-xl"><fa :icon="['fas', 'circle-info']" /><span class="pl-1">Info</span></div>
            <router-link :to="{ name: 'lexicon' }" class="flex rounded-md px-3 py-1 text-sm font-medium hover:underline">• Lexique</router-link>
        </div>

        <div v-if="connectedProfile">
            <hr class="my-3" />
            <div v-if="connectedProfile.roles.includes(RoleEnum.ADMIN)" class="flex flex-col">
                <div class="mt-2 text-xl"><fa :icon="['fas', 'cog']" /><span class="pl-1">Admin</span></div>
                <router-link :to="{ name: 'adminUserList' }" class="rounded-md px-3 py-2 text-sm font-medium hover:underline">
                    <fa :icon="['fas', 'user']" /><span class="pl-1">Gestion des utilisateurs</span>
                </router-link>
            </div>
        </div>
    </div>
</template>
