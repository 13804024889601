import { z } from 'zod';

export const nafSchema = z.object({
    id: z.number(),
    code: z.string(),
    label: z.string(),
    groupId: z.number(),
});

export const nafGroupSchema = z.object({
    id: z.number(),
    level: z.number(),
    label: z.string(),
    parent: z.number().nullable(),
});

export const marketEvolutionSchema = z.object({
    annee: z.number(),
    codeNafId: z.number().optional(),
    nafGroupId: z.number().optional(),
    margeCoCaMoyenne: z.number(),
    margeCoCaMediane: z.number(),
    margeBrutCaMoyenne: z.number(),
    margeBrutCaMediane: z.number(),
    valeurAjouteCaMoyenne: z.number(),
    valeurAjouteCaMediane: z.number(),
    ebeCaMoyenne: z.number(),
    ebeCaMediane: z.number(),
    rexCaMoyenne: z.number(),
    rexCaMediane: z.number(),
    resultatNetCaMoyenne: z.number(),
    resultatNetCaMediane: z.number(),
    croissanceMoyenne: z.number().nullable(),
    croissanceMediane: z.number().nullable(),
    masseSalarialeCaMoyenne: z.number().nullable(),
    masseSalarialeCaMediane: z.number().nullable(),
    nombreComptes: z.number(),
});

export const nafListSchema = z.array(nafSchema);
export const nafGroupListSchema = z.array(nafGroupSchema);
export const marketListSchema = z.array(marketEvolutionSchema);
