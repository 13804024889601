import { z } from 'zod';
import { ACTION_LOGOUT, LOCAL_STORAGE_JWT_KEY } from '@/store/modules/auth';
import store from '@/store/store';
import { router } from '@/router';

export function currencyFormater(data: number | null) {
    return data === null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(data);
}
export function currencyWithCentsFormater(data: number | null) {
    return data === null ? '' : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(data);
}
export function numberFormater(data: number | null, digits?: number) {
    return data === null ? '' : new Intl.NumberFormat('fr-FR', { maximumFractionDigits: digits ?? 2 }).format(data);
}
export function shorterNumberFormater(data: number | null) {
    return data === null
        ? ''
        : new Intl.NumberFormat('fr-FR', {
              notation: 'compact',
              compactDisplay: 'short',
          }).format(data);
}
export function prctFormater(data: number | null, digits?: number, minDigits?: number) {
    return data === null
        ? ''
        : new Intl.NumberFormat('fr-FR', {
              style: 'percent',
              maximumFractionDigits: digits ?? 1,
              minimumFractionDigits: minDigits ?? 0,
          }).format(data);
}
export function localeDate(date: string) {
    return new Date(date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
}
export function localeShortDate(date: string) {
    return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' });
}

export class FetchError extends Error {
    public body: Object | null;
    constructor(
        public status: number,
        public message: string,
        body: string | null
    ) {
        super();
        this.body = body ? JSON.parse(body) : null;
    }
}
export function fetchApi<T extends z.ZodTypeAny>(options: { url: string; method: 'POST' | 'PUT' | 'GET'; body?: object; schema?: T; timeout?: number }): Promise<z.TypeOf<T>>;
export function fetchApi(options: { url: string; method: 'POST' | 'PUT' | 'GET'; body?: object; timeout?: number }): undefined;
export function fetchApi<T extends z.ZodTypeAny>(options: { url: string; method: 'POST' | 'PUT' | 'GET'; body?: object; schema?: T; timeout?: number }): Promise<z.TypeOf<T>> | undefined {
    return fetch(`${import.meta.env.VITE_API ?? ''}${options.url}`, {
        method: options.method,
        headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem(LOCAL_STORAGE_JWT_KEY) ? `Bearer ${localStorage.getItem(LOCAL_STORAGE_JWT_KEY)}` : '' },
        body: options.body ? JSON.stringify(options.body) : undefined,
        signal: AbortSignal.timeout((options.timeout ?? 5) * 1000),
    }).then(async (response) => {
        if (response.status === 204) {
            return null;
        }
        if (response.status >= 200 && response.status < 300) {
            return response
                .json()
                .then((json) => {
                    if (!options.schema) {
                        return undefined;
                    }
                    const r = options.schema.safeParse(json);
                    if (r.success) {
                        return r.data as z.infer<T>;
                    } else if (localStorage.debug === 'true') {
                        console.log(r.error.errors);
                    }
                    console.log(r.error.errors);

                    throw new Error('An error occured');
                })
                .catch(console.log);
        }
        if (response.status === 401) {
            await store.dispatch(ACTION_LOGOUT);
            await router.push({ name: 'signin' });
        }
        throw new FetchError(response.status, 'An error occured', await response.json());
    });
}

declare global {
    interface Array<T> {
        first(): T | undefined;
        last(): T | undefined;
        filterNullAndUndefined(): Exclude<T, null | undefined>[];
        filterError(): Exclude<T, Error>[];
        isEmpty(): boolean;
        lastIndex(): number;
        /**
         * Shuffle in place
         */
        shuffle(): T[];

        // eslint-disable-next-line no-unused-vars
        sum(this: number[]): number;
        // eslint-disable-next-line no-unused-vars
        avg(this: number[]): number;
        // eslint-disable-next-line no-unused-vars
        unique<T extends number | string>(this: T[]): T[];
        // eslint-disable-next-line no-unused-vars
        uniqueKey<T extends object>(this: T[], key: keyof T): T[];

        sample(): T;
    }
    interface String {
        toUpperCaseFirst(): string;
        isBlank(): boolean;
    }
    interface Number {
        isInRange(center: number, range: number): boolean;
        isBetween(min: number, max: number): boolean;
    }
    interface Function {
        if<T>(condition: boolean, ...args: T[]): void;
    }
}

Array.prototype.first = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.find(Boolean);
};

Array.prototype.last = function () {
    const n = this.length;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return n > 0 ? this[n - 1] : undefined;
};

Array.prototype.filterNullAndUndefined = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.filter((item) => item !== null && item !== undefined);
};
Array.prototype.filterError = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.filter((item) => !(item instanceof Error));
};

Array.prototype.isEmpty = function () {
    return this.length === 0;
};

Array.prototype.lastIndex = function () {
    return this.length - 1;
};

Array.prototype.shuffle = function () {
    for (let i = this.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [this[i], this[j]] = [this[j], this[i]];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this;
};

Array.prototype.sum = function () {
    return this.reduce((a, b) => a + b, 0);
};

Array.prototype.avg = function () {
    return this.sum() / this.length || 0;
};

Array.prototype.unique = function () {
    return [...new Set(this)];
};

Array.prototype.uniqueKey = function <T>(key: keyof T) {
    return [...new Map(this.map((item) => [item[key], item])).values()] as T[];
};

Array.prototype.sample = function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this[Math.floor(Math.random() * this.length)];
};

String.prototype.toUpperCaseFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.isBlank = function () {
    return this.length === 0;
};

Number.prototype.isBetween = function (min: number, max: number) {
    if (min > max) {
        [min, max] = [max, min];
    }
    return (this as number) >= min && (this as number) <= max;
};
Number.prototype.isInRange = function (center: number, range: number) {
    return (this as number) >= center - range / 2 && (this as number) <= center + range / 2;
};

Function.prototype.if = function (condition, ...args) {
    return condition ? this(...args) : undefined;
};

export type MinMax = {
    min: number | null;
    max: number | null;
};

export type Pagination = {
    offset: number;
    resultPerPage: number;
};

export function formatOrdinals(n: number) {
    const pr = new Intl.PluralRules('fr-FR', { type: 'ordinal' });
    const suffixes = new Map([
        ['one', 'er'],
        ['two', 'nd'],
        ['few', 'ème'],
        ['other', 'ème'],
    ]);
    const rule = pr.select(n);
    return `${n}${suffixes.get(rule)}`;
}

// Convert WKT string into google.maps.Polygon
export function parseWKT(wkt: string) {
    const polygons: { lat: number; lng: number }[] = [];
    const regex = /\(([^(]*)\)/gi;

    let match;

    while ((match = regex.exec(wkt)) !== null) {
        const path = match[1]
            .replace(/[()]/gi, '')
            .split(',')
            .map((point) => {
                const [lng, lat] = point.trim().split(' ').map(Number);
                return { lat, lng };
            });
        polygons.push(...path);
    }
    return polygons;
}
export const toggleSwitchDT = {
    colorScheme: {
        light: {
            background: 'var(--primary)',
            hover: {
                background: 'var(--primary)',
            },
            checked: {
                background: 'var(--primary)',
                hover: {
                    background: 'var(--primary)',
                },
            },
        },
    },
};
